* {
  margin: 0;
  -ms-overflow-style: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 95vh;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body > #root > div {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body {
  position: relative;
}

.audioPlayer {
  width: auto;
}

.audioPlayer__label {
  color: #838383;
  font-size: 13px;
  font-weight: 600;
}

.audioPlayer__container {
  position: absolute;
  left: 0;
  width: min-content;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #ffffff;
}

.audioPlayerGameBody {
  top: 0;
  margin-top: 15px;
  margin-left: 10px;
  flex-direction: column;
}

.audioPlayerGameOptions {
  bottom: 0;
  margin-bottom: 20px;
  margin-left: 5px;
  flex-direction: column-reverse;
}

.hideAudioPlayer {
  display: none;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.splash {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20%;
}

.splash__titleContainer {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20%;
  padding-bottom: 20%;
  width: max-content;
  height: auto;
  border-radius: 5px;
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    170deg,
    rgba(2, 0, 36, 1) 50%,
    rgba(217, 68, 150, 1) 75%,
    rgba(244, 125, 187, 1) 100%
  );
  background: -webkit-linear-gradient(
    170deg,
    rgba(2, 0, 36, 1) 50%,
    rgba(217, 68, 150, 1) 75%,
    rgba(244, 125, 187, 1) 100%
  );
  background: linear-gradient(
    170deg,
    rgba(2, 0, 36, 1) 50%,
    rgba(217, 68, 150, 1) 75%,
    rgba(244, 125, 187, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#f47dbb",GradientType=1);
}

.splash__title {
  font-size: 34px;
  margin-bottom: 1%;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
}

.splash__subtitle {
  margin-top: 20px;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}

.loaderContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20%;
  width: 100%;
}

.loader {
  margin-top: 15%;
  width: 200px;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}

.loader span {
  font-size: 30px;
  margin: auto;
  color: #000000;
  font-weight: 500;
}

.loader__shapes {
  display: flex;
  flex-direction: row;
  width: 170px;
  justify-content: space-evenly;
}

.ball {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d94496;
  animation: bounce 0.5s alternate infinite;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 40px solid #d94496;
  animation: bounce 0.5s alternate infinite;
  animation-delay: 0.16s;
}

.square {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #d94496;
  animation: bounce 0.5s alternate infinite;
  animation-delay: 0.32s;
}

@keyframes bounce {
  from {
    transform: scaleX(1.25);
  }
  to {
    transform: translateY(-50px) scale(1);
  }
}

.title {
  font-size: 34px;
  color: #000000;
  font-weight: 500;
  text-align: center;
}

.whiteTitle {
  color: #ffffff !important;
}

.centerText {
  text-align: center;
}

.gameWinners {
  justify-content: space-between !important;
}

.gameWinners__list {
  font-size: 20px;
  width: max-content;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
}

.gameWinners__listRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}

.gameWinners__listItem {
  color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
}

.gameWinners__playerIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.gameWinners__buttonsRow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -15px;
  margin-bottom: 15px;
}

.gameWinners__buttonText {
  padding-left: 15px;
  padding-right: 15px;
}

.gameOptions {
  position: relative;
  padding: 100px;
  display: flex;
  min-height: 350px;
  height: auto;
  width: 350px;
  margin-bottom: 3%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  border: solid;
  padding: 10%;
  border-color: #000000;
  border-width: 1px;
  border-radius: 5px;
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    170deg,
    rgba(2, 0, 36, 1) 25%,
    rgba(71, 71, 71, 1) 75%,
    rgba(107, 107, 107, 1) 100%
  );
  background: -webkit-linear-gradient(
    170deg,
    rgba(2, 0, 36, 1) 25%,
    rgba(71, 71, 71, 1) 75%,
    rgba(107, 107, 107, 1) 100%
  );
  background: linear-gradient(
    170deg,
    rgba(2, 0, 36, 1) 25%,
    rgba(71, 71, 71, 1) 75%,
    rgba(107, 107, 107, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#6b6b6b",GradientType=1);
}

.gameOptions__form {
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gameOptions__playerName {
  margin: 10px;
  padding: 8px;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  border: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    170deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 182, 213, 1) 100%
  );
  background: -webkit-linear-gradient(
    170deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 182, 213, 1) 100%
  );
  background: linear-gradient(
    170deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 182, 213, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffb6d5",GradientType=1);
}

.gameOptions__selectorsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.gameOptions__container {
  width: auto;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
}

.gameOptions__option {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid;
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.selectedDifficultyOption {
  background-color: #d94496;
  border-color: #ffffff;
  border-width: 2px;
}

.nonselectedDifficultyOption {
  border-color: #ffffff;
  border-width: 1px;
}

.gameOptions__difficultyButton {
  position: fixed;
  opacity: 0;
  pointer-events: none;
}

.gameOptions__difficultyLabelSelected {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.gameOptions__difficultyLabelUnselected {
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

.gameOptions__gamePieceContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.gameOptions__gamePieceButton {
  width: 50px !important;
  height: 45px !important;
  padding: 2px !important;
  margin: 2px !important;
}

.gameOptions__gamePieceImg {
  width: 40px;
  height: 35px;
  border-radius: 50%;
}

.gameOptions__button {
  margin: 5px;
  width: 200px;
  height: auto;
  padding: 8px;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 600;
  font-size: 25px;
  background: rgb(217, 68, 150);
  background: -moz-linear-gradient(
    170deg,
    rgba(217, 68, 150, 1) 45%,
    rgba(0, 0, 0, 1) 93%
  );
  background: -webkit-linear-gradient(
    170deg,
    rgba(217, 68, 150, 1) 45%,
    rgba(0, 0, 0, 1) 93%
  );
  background: linear-gradient(
    170deg,
    rgba(217, 68, 150, 1) 45%,
    rgba(0, 0, 0, 1) 93%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d94496",endColorstr="#000000",GradientType=1);
}

.gameBody {
  position: relative;
  border: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  padding: 5%;
  padding-top: 0% !important;
  margin-top: 1%;
  margin-bottom: 3%;
  display: flex;
  height: max-content;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    70deg,
    rgba(2, 0, 36, 1) 35%,
    rgba(217, 68, 150, 1) 75%,
    rgba(244, 125, 187, 1) 100%
  );
  background: -webkit-linear-gradient(
    70deg,
    rgba(2, 0, 36, 1) 35%,
    rgba(217, 68, 150, 1) 75%,
    rgba(244, 125, 187, 1) 100%
  );
  background: linear-gradient(
    70deg,
    rgba(2, 0, 36, 1) 35%,
    rgba(217, 68, 150, 1) 75%,
    rgba(244, 125, 187, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#f47dbb",GradientType=1);
}

.gameBody__profileButton {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  height: max-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 600;
  font-size: 25px;
  color: #ffffff;
  background-color: #000000;
  border-color: #ffffff;
  border-width: 2px;
  border-radius: 5px;
}

.gameBody__profileIcon {
  height: 45px;
  width: 45px;
  font-weight: 600;
}

.gameBody__profileLabel {
  font-size: 13px;
}

.gameBody__details {
  color: #ffffff;
  margin: 5%;
  font-weight: 500;
  font-size: 18px;
  flex-wrap: wrap;
  text-align: center;
}

.gameBody__hudContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
  align-items: center;
  margin-bottom: 55px;
  margin-right: 12px;
}

.gameBody__hud {
  color: #ffffff;
  font-weight: 800;
  font-size: 18px;
}

.playerName {
  width: 30px;
  text-align: center;
  flex-wrap: wrap;
  margin-right: 28px;
}

.extraTopMargin {
  margin-top: 5px;
}

.instructionsContainer {
  width: 420px !important;
}

.gameBody__buttonContainer {
  display: flex;
  flex-direction: row;
}

.gameBody__button {
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  height: auto;
  width: max-content;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  background-color: #d94496;
  border-color: #ffffff;
  border-width: 2px;
  border-radius: 5px;
}

.glassBridge {
  border: solid;
  border-color: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 10px;
  border-width: 1px;
  border-radius: 5px;
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    170deg,
    rgba(2, 0, 36, 1) 25%,
    rgba(71, 71, 71, 1) 75%,
    rgba(107, 107, 107, 1) 100%
  );
  background: -webkit-linear-gradient(
    170deg,
    rgba(2, 0, 36, 1) 25%,
    rgba(71, 71, 71, 1) 75%,
    rgba(107, 107, 107, 1) 100%
  );
  background: linear-gradient(
    170deg,
    rgba(2, 0, 36, 1) 25%,
    rgba(71, 71, 71, 1) 75%,
    rgba(107, 107, 107, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#6b6b6b",GradientType=1);
}

.glassBridge__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.glassBridge__tile {
  height: 40px;
  width: 60px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
}

.glassBridge__gamePieceImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.tileCorrect {
  border: solid;
  border-width: 3px;
  border-color: #008000;
  background-color: rgba(144, 238, 144, 0.5);
}

.tileWrong {
  border: solid;
  border-width: 3px;
  border-color: #ff0000;
  background-color: #faa0a0;
}

.buttonIcon {
  height: 35px;
  width: 35px;
  color: #ffffff;
}

.buttonIcon2 {
  height: 25px;
  width: 25px;
  color: #000000;
}

.hideComponent {
  display: none;
}

.glassBridge__Text {
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.profile__body {
  padding: 10px;
  min-width: 250px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile__bodyRow {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 5px;
  justify-content: space-between;
}

.profileIcon {
  margin-top: 15px;
  width: 85px;
  height: 75px;
  border-radius: 50%;
}

.profile__error {
  text-align: center;
  justify-self: center;
  margin-bottom: 35px;
}

.maxWidth90 {
  width: 90% !important;
}

/* Mobile CSS */
@media only screen and (max-width: 600px) {
  body {
    min-height: 89vh;
    height: auto;
    width: 100vw;
    max-height: 105%;
    padding-bottom: 5px;
  }

  .audioPlayer {
    margin-top: 5px;
  }

  .splash {
    margin-top: 35%;
  }

  .splash__titleContainer {
    width: auto;
    margin: 10px;
  }

  .loaderContainer {
    width: 100%;
    height: 100%;
    padding-left: 5%;
    margin-top: 5%;
  }

  .loader {
    margin-left: 10%;
  }

  .title {
    font-size: 30px;
  }

  .gameOptions {
    margin: 0%;
    width: 75vw;
    height: auto;
  }

  .gameOptions__button {
    width: 160px;
    height: 50px;
    font-size: 20px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 7px;
    font-weight: 600;
  }

  .gameOptions__gamePieceContainer {
    margin-top: 5px;
  }
  .gameOptions__gamePieceButton {
    width: 39px !important;
    height: 39px !important;
    padding: 2px !important;
    margin: 2px !important;
  }

  .gameOptions__gamePieceImg {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .glassBridge__tile {
    margin: 5px;
  }

  .gameBody {
    width: 340px;
    padding: 3%;
  }

  .gameBody__details {
    text-align: center;
    flex-wrap: wrap;
    font-size: 20px;
  }

  .gameBody__hudContainer {
    margin-bottom: 70px;
  }

  .playerName {
    margin-right: 25px;
  }

  .gameBody__profileButton {
    border-width: 1px;
  }

  .gameBody__button {
    height: fit-content;
    width: fit-content;
    padding: 6px;
    color: #ffffff;
    background-color: #d94496;
    border-color: #ffffff;
    border-width: 1px;
    font-weight: 600;
  }

  .instructionsContainer {
    width: 320px !important;
    padding: 20px;
    margin-top: 15%;
  }

  .gameWinners__listRow {
    margin-left: 0px;
    margin-right: 0px;
  }

  .audioPlayer__container {
    margin-left: 0px;
  }

  .audioPlayerGameBody {
    margin-left: 5px;
  }

  .audioPlayerGameOptions {
    margin-left: 5px;
  }
}

@media only screen and (max-height: 750px) and (min-width: 600px) {
  body {
    height: 100%;
    padding-top: 1%;
  }
}
